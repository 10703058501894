import { del, get, post, put } from './network';
import { StoresResponse } from '../models/StoresResponse';
import { User } from '../models/User';
import { DeviceInfo, Profile } from '../models/Profile';
import { LoginResponse, RegisterResponse } from '../models/SignResponse';
import { Balance } from '../models/Balance';

export const API = {
  CSD: {
    getCompanies: () => get<any>('/tablet/companies'),
    getStores: (companyID?: string) => get<StoresResponse>(`/tablet/stores${companyID}`),
    registerStore: (macAddress: string, storeID: number) => post<any>('/tablet/register-store', { mac_address: macAddress, store_id: storeID }),
    login: (nfcID: string) => post<User>(`/tablet/login/${nfcID}`, {}, undefined, true),
    withdrawal: (storeID: number, tableID: number, userToken: string) => post<any>('/tablet/withdrawal-cash', { store_id: storeID, tablet_id: tableID, user_token: userToken }, undefined, true),
  },
  Users: {
    signUp: (user: Profile, password: string, deviceInfo: DeviceInfo) => put<any>('/sign-up', {
      first_name: user.first_name, last_name: user.last_name, email: user.email, password1: password, password2: password, marketing: user.marketing, device_info: deviceInfo,
    }),
    resendEmailVerification: (email: string) => post<RegisterResponse>('/request-verification', { email }),
    login: (email: string, password: string) => post<LoginResponse>('/elogin', { email, password }),
    profile: () => get<Profile>('/profile'),
    requestChangePassword: (email: string) => post<any>('/request-password', { email }),
    balance: () => get<Balance>('/profile/balance'),
    withdrawal: (amount: number) => post<any>('/profile/withdrawal', { amount: Number(amount) }, undefined, true),
    share: (email: string) => post<any>('/share', { email }, undefined, true),
    addSmartCard: (smartCard?: string) => post<any>('/profile/smart-card', { smart_card: smartCard }, undefined, true),
    deleteSmartCard: (smartCard?: string) => del<any>('/profile/smart-card', { smart_card: smartCard }, undefined, true),
  },
};
