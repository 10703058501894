import React, { FC } from 'react';

import nfcIcon from '../../assets/nfc.svg';
import balanceIcon from '../../assets/balance.svg';
import dollarIcon from '../../assets/dollar.svg';
import { COLORS } from '../utils/Colors';
import { appWidth } from '../screens/CSD/WelcomeCSD';

export enum WidgetType {
  LOGIN = 'LOGIN',
  BALANCE = 'BALANCE',
  CASH = 'CASH'
}

const Title = {
  LOGIN: '1. LOG IN',
  BALANCE: '2. CHECK YOUR BALANCE',
  CASH: '3. GET YOUR CASH',
};

const Body = {
  LOGIN: 'Hold your phone or Olyns card over reader to log in.',
  BALANCE: 'See how much cash you’ve earned.',
  CASH: 'Customer service will pay you cash for your deposits.  Keep recycling to earn more.',
};

const TypeColor = {
  LOGIN: COLORS.BLUE,
  BALANCE: COLORS.GREEN,
  CASH: COLORS.LIGHT_GREEN,
};

const TypeIcon = {
  LOGIN: nfcIcon,
  BALANCE: balanceIcon,
  CASH: dollarIcon,
};

interface WelcomeWidgetProps {
  type: WidgetType
}

export const WelcomeWidget: FC<WelcomeWidgetProps> = ({ type }) => {
  const titleStyle = { color: TypeColor[type], fontWeight: 'bold' };

  return (
    <div className="WelcomeWidget" style={{ width: appWidth ? '80%' : '90%' }}>
      <img alt="icon" src={TypeIcon[type]} width={appWidth ? '10%' : '15%'} style={{ marginBottom: 20 }} />
      <p style={titleStyle}>{Title[type]}</p>
      <p>{Body[type]}</p>
    </div>
  );
};
