import React, { CSSProperties, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import screenImage from '../../assets/images/welcome-pattern.png';
import logo from '../../assets/full-logo.svg';
import { Button } from '../components/Button';
import { PressableText } from '../components/PressableText';
import { Separator } from '../components/Separator';
import { appWidth } from './CSD/WelcomeCSD';
import { Router } from '../router/Router';
import { NotAuthProvider } from '../components/NotAuthProvider';

export const Welcome: FC = () => {
  const navigation = useNavigate();
  const styles = {
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
    } as CSSProperties,
    bgImage: {
      height: '60%',
      position: 'fixed',
      bottom: 5,
      right: 0,
    } as CSSProperties,
    mainContainer: {
      width: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: appWidth ? 'normal' : 'center',
      flexDirection: 'column',
      marginLeft: appWidth ? 10 : 100,
      marginTop: appWidth ? 50 : 0,
    } as CSSProperties,
    logo: {
      width: '60%',
    } as CSSProperties,
    text: {
      width: '80%',
      textAlign: 'center',
    } as CSSProperties,
  };

  const handleSignUp = () => Router(navigation).goToSignUp();
  const handleSignIn = () => Router(navigation).goToSignIn();
  return (
    <NotAuthProvider>
      <img style={styles.bgImage} src={screenImage} alt="background" />
      <div style={styles.root}>
        <div style={styles.mainContainer}>
          <img style={styles.logo} src={logo} alt="logo" />
          <Separator size="S" />
          <span style={styles.text}>Get an Olyns account. Earn cash for recycling and help the planet.</span>
          <Separator size="M" />
          <Button type="PRIMARY" width="80%" title="Sign me up" onClick={handleSignUp} />
          <Separator size="S" />
          <PressableText width="80%" title="Already joined? Sign in!" textAlign="center" onClick={handleSignIn} />
        </div>
      </div>
    </NotAuthProvider>
  );
};
