import React, { CSSProperties, FC } from 'react';
import { COLORS } from '../utils/Colors';

type TextAlign = 'center' | 'start' | 'end';

export enum PressableTextSize {
  S = 14,
  M = 16,
  L = 18
}

interface PressableTextProps {
  title: string;
  width: string;
  size?: PressableTextSize;
  textAlign?: TextAlign;
  onClick: () => void;
}

export const PressableText: FC<PressableTextProps> = ({ title, width, size = PressableTextSize.M, textAlign = 'start', onClick }) => {
  PressableText.defaultProps = {
    textAlign: 'start',
    size: PressableTextSize.M,
  };

  const textStyle: CSSProperties = {
    width,
    textAlign,
    cursor: 'pointer',
    color: COLORS.LIGHT_GRAY,
    fontSize: size,
  };

  return <span style={textStyle} onClick={onClick}>{title}</span>;
};
