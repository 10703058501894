import React, { CSSProperties, FC } from 'react';
import { COLORS } from '../utils/Colors';

type ButtonType = 'PRIMARY' | 'DISABLED';

enum ButtonColor {
  PRIMARY = COLORS.BLUE,
  DISABLED = COLORS.VERY_LIGHT_GRAY,
}

interface ButtonProps {
  type: ButtonType
  width: string,
  title: string,
  onClick: () => void;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({ type, width, title, onClick, disabled }) => {
  Button.defaultProps = {
    disabled: false,
  };

  const buttonStyle: CSSProperties = {
    width,
    background: ButtonColor[type],
    color: 'white',
    border: 0,
    paddingBlock: 10,
    fontSize: 18,
    borderRadius: 5,
    boxShadow: disabled ? '0 0 0' : `5px 5px 10px ${ButtonColor[type]}`,
  };

  return <button type="button" style={buttonStyle} onClick={onClick} disabled={disabled}>{title}</button>;
};
