import React, { ChangeEvent, CSSProperties, FC } from 'react';
import { PressableText, PressableTextSize } from './PressableText';
import { Separator } from './Separator';

interface CustomCheckBoxProps {
  title: string;
  checked: boolean;
  onClick?: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const CustomCheckBox: FC<CustomCheckBoxProps> = ({ title, checked, onClick = () => {}, onChange }) => {
  CustomCheckBox.defaultProps = {
    onClick: () => {},
  };

  const styles = {
    root: {
      display: 'flex',
      width: '100%',
    } as CSSProperties,
  };

  return (
    <div style={styles.root}>
      <input type="checkbox" onClick={onClick} checked={checked} onChange={onChange} />
      <Separator size="S" orientation="H" />
      <PressableText onClick={onClick} width="100%" title={title} size={PressableTextSize.S} />
    </div>
  );
};
