import React, { CSSProperties, FC, ReactNode } from 'react';
import { COLORS } from '../../utils/Colors';

interface ModalContainerProps {
  children: ReactNode;
}

export const ModalContainer: FC<ModalContainerProps> = ({ children }) => {
  const mainContainerStyle = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties;
  const containerStyle = {
    maxHeight: 450,
    width: 400,
    borderRadius: 20,
    background: 'white',
    paddingBlock: 30,
    paddingInline: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `0 0 20px ${COLORS.VERY_LIGHT_GRAY}`,
  } as CSSProperties;

  return (
    <div style={mainContainerStyle}>
      <div style={containerStyle}>
        {children}
      </div>
    </div>
  );
};
