import React, { ChangeEvent, CSSProperties, FC, KeyboardEventHandler, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appWidth } from './CSD/WelcomeCSD';
import screenImage from '../../assets/images/welcome-pattern.png';
import { Heading } from '../components/Heading';
import { Router } from '../router/Router';
import { Input } from '../components/Input';
import { Separator } from '../components/Separator';
import { Button } from '../components/Button';
import { emailChecker } from '../utils/InputChecker';
import { API } from '../utils/API';
import { Loading } from '../components/Loading';
import { ToastBar } from '../components/ToastBar';
import { NotAuthProvider } from '../components/NotAuthProvider';

export const PasswordRecovery: FC = () => {
  const navigation = useNavigate();

  const styles = {
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
    } as CSSProperties,
    bgImage: {
      height: appWidth ? '55%' : '60%',
      position: 'fixed',
      bottom: 5,
      right: 0,
    } as CSSProperties,
    mainContainer: {
      paddingBlock: 20,
      width: 400,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: appWidth ? 30 : 100,
      marginTop: appWidth ? 50 : 0,
    } as CSSProperties,
    form: {
      width: '100%',
      display: 'flex',
      flex: appWidth ? 0.3 : 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    } as CSSProperties,
  };

  const handleBackPressed = () => Router(navigation).goToSignIn();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isToastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setEmail(input);

    if (!emailChecker(input)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const buttonDisabled = !email.length || emailError;

  const handleSubmit = () => {
    setLoading(true);
    API.Users.requestChangePassword(email)
      .then(() => setLoading(false))
      .catch((error) => {
        setLoading(false);
        setToastVisible(true);
        setTimeout(() => setToastVisible(false), 3000);
        if (error.errorCode) {
          setToastMessage(error.message);
          return;
        }
        setToastMessage('Something went wrong');
      });
  };

  const enterKeyPressed = (event: KeyboardEventHandler<HTMLInputElement>) => {
    // @ts-ignore
    const pressed = event.key.toLowerCase();
    if (pressed === 'enter' && !buttonDisabled) {
      handleSubmit();
    }
  };

  return (
    <NotAuthProvider>
      <img style={styles.bgImage} src={screenImage} alt="background" />
      <div style={styles.root}>
        {isLoading && <Loading isVisible={isLoading} />}
        {isToastVisible && <ToastBar isVisible={isToastVisible} message={toastMessage} />}
        <div style={styles.mainContainer}>
          <Heading title="Password recovery" onBack={handleBackPressed} />
          <div style={styles.form}>
            <Input onError={emailError} onChange={handleEmailChange} errorMessage="Write a valid email." placeholder="Email Address" type="email" onKeyPressed={enterKeyPressed} />
            <Separator size="M" />
            <Button type={buttonDisabled ? 'DISABLED' : 'PRIMARY'} width="100%" title="Submit" onClick={handleSubmit} disabled={buttonDisabled} />
          </div>
        </div>
      </div>
    </NotAuthProvider>
  );
};
