import React, { CSSProperties, FC } from 'react';
import { ProfileIcon } from '../../assets/icons/ProfileIcon';
import { COLORS } from '../utils/Colors';
import { SignOutIcon } from '../../assets/icons/SignOutIcon';
import { ChevronRightIcon } from '../../assets/icons/ChevronRightIcon';
import { NFCIcon } from '../../assets/icons/NFCIcon';
import { Separator } from './Separator';

interface ProfileWidgetProps {
  name: string;
  isOpen: boolean;
  handleOpen: () => void;
  handleNFCCard: () => void;
  handleSignOut: () => void;
}

export const ProfileWidget: FC<ProfileWidgetProps> = ({ name, isOpen, handleOpen, handleNFCCard, handleSignOut }) => {
  const styles = {
    root: {
      position: 'fixed',
      top: 20,
      right: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    } as CSSProperties,
    iconContainer: {
      width: 40,
      height: 40,
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 20,
      cursor: 'pointer',
    } as CSSProperties,
    container: {
      marginTop: 15,
      background: 'white',
      padding: 10,
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: `0 0 30px ${COLORS.VERY_LIGHT_GRAY}`,
    } as CSSProperties,
    decorator: {
      width: 15,
      height: 15,
      alignSelf: 'flex-end',
      transform: 'rotate(45deg)',
      marginTop: -14,
      marginRight: 2,
      background: 'white',
    } as CSSProperties,
    name: {
      fontWeight: 500,
    } as CSSProperties,
    divider: {
      width: '100%',
      height: 1,
      background: COLORS.VERY_LIGHT_GRAY,
      marginBlock: 10,
    } as CSSProperties,
    signOutContainer: {
      width: 200,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    } as CSSProperties,
    signOut: {
      color: COLORS.LIGHT_GRAY,
      marginInline: 10,
      flex: 1,
    } as CSSProperties,
  };

  return (
    <>
      <div style={styles.root}>
        <div style={styles.iconContainer} className="box-shadow-widget" onClick={handleOpen}>
          <ProfileIcon width={20} height={20} color={COLORS.DARK_GRAY} />
        </div>
        {
          isOpen
          && (
          <div style={styles.container}>
            <div style={styles.decorator} />
            <span style={styles.name}>{name}</span>
            <div style={styles.divider} />
            <div style={styles.signOutContainer} onClick={handleNFCCard}>
              <NFCIcon width={16} height={16} color={COLORS.DARK_GRAY} />
              <span style={styles.signOut}>Add smart card ID</span>
              <ChevronRightIcon width={16} height={16} color={COLORS.DARK_GRAY} />
            </div>
            <Separator size="XS" />
            <div style={styles.signOutContainer} onClick={handleSignOut}>
              <SignOutIcon width={16} height={16} color={COLORS.DARK_GRAY} />
              <span style={styles.signOut}>Sign out</span>
              <ChevronRightIcon width={16} height={16} color={COLORS.DARK_GRAY} />
            </div>
          </div>
          )
        }
      </div>
    </>
  );
};
