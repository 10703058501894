import React, { CSSProperties, FC } from 'react';

interface HeadingProps {
  title: string;
  onBack: () => void;
}

export const Heading: FC<HeadingProps> = ({ title, onBack }) => {
  const styles = {
    root: {
      width: '100%',
    } as CSSProperties,
    label: {
      fontWeight: 500,
    } as CSSProperties,
  };

  return (
    <div style={styles.root}>
      <button type="button" className="btn no-border-button" onClick={onBack}><i className="fa-solid fa-arrow-left" /></button>
      <span style={styles.label}>{title}</span>
    </div>
  );
};
