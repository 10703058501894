import React, { CSSProperties, FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appWidth } from './CSD/WelcomeCSD';
import screenImage from '../../assets/images/welcome-pattern.png';
import envelopOpen from '../../assets/images/envelop_open.png';
import { Button } from '../components/Button';
import { PressableText } from '../components/PressableText';
import { Separator } from '../components/Separator';
import { EmailVerificationProps, Router } from '../router/Router';
import { API } from '../utils/API';
import { Loading } from '../components/Loading';
import { NotAuthProvider } from '../components/NotAuthProvider';

export const EmailVerification: FC = () => {
  const navigation = useNavigate();
  const routerProps = useLocation().state as EmailVerificationProps;
  
  const styles = {
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
    } as CSSProperties,
    bgImage: {
      height: appWidth ? '45%' : '60%',
      position: 'fixed',
      bottom: 5,
      right: 0,
    } as CSSProperties,
    mainContainer: {
      paddingBlock: 20,
      width: 400,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: appWidth ? 'start' : 'center',
      alignItems: 'center',
      marginLeft: appWidth ? 30 : 100,
      marginTop: appWidth ? 100 : 0,
    } as CSSProperties,
    envelop: {
      width: '35%',
    } as CSSProperties,
    title: {
      fontWeight: 'bold',
      fontSize: 18,
    } as CSSProperties,
    body: {
      textAlign: 'center',
    } as CSSProperties,
  };

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleOk = () => Router(navigation).goToWelcome();
  const handleResendEmail = async () => {
    setLoading(true);
    await API.Users.resendEmailVerification(routerProps.email);
    setLoading(false);
  };

  return (
    <NotAuthProvider>
      <img style={styles.bgImage} src={screenImage} alt="background" />
      {isLoading && <Loading isVisible={isLoading} />}
      <div style={styles.root}>
        <div style={styles.mainContainer}>
          <img src={envelopOpen} alt="envelop" style={styles.envelop} />
          <Separator size="S" />
          <span style={styles.title}>Great!</span>
          <Separator size="S" />
          <span style={styles.body}>Check your email to confirm the account and activate it.</span>
          <Separator size="L" />
          <Button onClick={handleOk} width="100%" type="PRIMARY" title="OK" />
          <Separator size="S" />
          <PressableText onClick={handleResendEmail} width="100%" title="Resend email" textAlign="center" />
          <Separator size="S" />
        </div>
      </div>
    </NotAuthProvider>
  );
};
