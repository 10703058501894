import React, { FC } from 'react';
import { COLORS } from '../utils/Colors';

interface ErrorCashOutProps {
  onClick: () => void,
  errorMessage: string
}

export const ErrorCashOut: FC<ErrorCashOutProps> = ({ onClick, errorMessage }) => (
  <div className="WhiteContainerFlex">
    <div className="SmallRoundContainer" style={{ backgroundColor: COLORS.TRANSLUCENT_RED, color: COLORS.RED }}>
      <i className="fa-solid fa-xmark" />
    </div>
    <h3 style={{ fontWeight: 'bold', color: COLORS.RED, marginTop: 20 }}>Error!</h3>
    <p style={{ color: COLORS.DARK_GRAY }}>{errorMessage}</p>
    <button type="button" className="btn AcceptButton" style={{ width: 300 }} onClick={onClick}>Try again</button>
  </div>
);
