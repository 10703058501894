import React from 'react';

// Router.ts
import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';

import './App.css';

// Screens
import { SetStore } from './main/screens/CSD/SetStore';
import { WelcomeCSD } from './main/screens/CSD/WelcomeCSD';
import { Balance } from './main/screens/CSD/Balance';
import { RepresentativeConfirmation } from './main/screens/CSD/RepresentativeConfirmation';
import { CustomerConfirmation } from './main/screens/CSD/CustomerConfirmation';
import { CashOutResult } from './main/screens/CSD/CashOutResult';
import { Welcome } from './main/screens/Welcome';
import { SignIn } from './main/screens/SignIn';
import { SignUp } from './main/screens/SignUp';
import { EmailVerification } from './main/screens/EmailVerification';
import { PasswordRecovery } from './main/screens/PasswordRecovery';
import { VerificationEmailResend } from './main/screens/VerificationEmailResend';
import { Home } from './main/screens/Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/redemption-cash-3da5vj9xb2z/set-store" element={<SetStore />} />
        <Route path="/redemption-cash-3da5vj9xb2z/welcome" element={<WelcomeCSD />} />
        <Route path="/redemption-cash-3da5vj9xb2z/balance" element={<Balance />} />
        <Route path="/redemption-cash-3da5vj9xb2z/representative-confirmation" element={<RepresentativeConfirmation />} />
        <Route path="/redemption-cash-3da5vj9xb2z/customer-confirmation" element={<CustomerConfirmation />} />
        <Route path="/redemption-cash-3da5vj9xb2z/cash-out-result" element={<CashOutResult />} />

        <Route path="/" element={<Navigate replace to="/welcome" />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/email-verification-resend" element={<VerificationEmailResend />} />
        
        <Route path="/home" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
