export const checkStore = () => {
  try {
    const store = localStorage.getItem('store');
    if (store !== null) {
      return JSON.parse(store);
    }
    return undefined;
  } catch (error) {
    console.log(error);
    return error;
  }
};
