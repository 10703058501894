import { API } from './API';
import { setAuthToken } from './APIConfig';

export const doLogin = async (email: string, password: string) => {
  const loginResponse = await API.Users.login(email, password);
  if (loginResponse) {
    setAuthToken(loginResponse.key);
    return await API.Users.profile();
  }
};
