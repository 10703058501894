import React, { ChangeEvent, CSSProperties, FC, useState } from 'react';
import { Buffer } from 'buffer';
import { Input } from '../Input';
import { Separator } from '../Separator';
import { COLORS } from '../../utils/Colors';
import { Button } from '../Button';
import { PressableText } from '../PressableText';
import { Loading } from '../Loading';
import { ToastBar } from '../ToastBar';

interface SupportModalProps {
  email?: string;
  name?: string;
  onCancel: () => void;
}

export const SupportModal: FC<SupportModalProps> = ({ email, name, onCancel }) => {
  SupportModal.defaultProps = {
    email: '',
    name: '',
  };
  const styles = {
    label: {
      width: '100%',
      marginBottom: 5,
    } as CSSProperties,
    textarea: {
      width: '100%',
      borderColor: COLORS.VERY_LIGHT_GRAY,
      height: 300,
      padding: 5,
      borderRadius: 5,
      outline: 'none',
    } as CSSProperties,
  };
  const mainContainerStyle = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as CSSProperties;
  const containerStyle = {
    maxHeight: 550,
    width: 400,
    borderRadius: 20,
    background: 'white',
    paddingBlock: 30,
    paddingInline: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `0 0 20px ${COLORS.VERY_LIGHT_GRAY}`,
  } as CSSProperties;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isToast, setToast] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');
  const handleNoChange = (event: ChangeEvent<HTMLInputElement>) => console.log(event.target.value);
  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value);

  const handleSend = () => {
    const authorization = Buffer.from(`${email}/token:ckVLahMFasDjq7jaq4LufA2YWLoGUlSx1pCfflMO`).toString('base64');
    const request = {
      request: {
        requester: {
          name,
          email,
        },
        subject: 'Olyns help',
        comment: {
          body: message,
        },
      },
    };
    setLoading(true);
    fetch('https://olyns.zendesk.com/api/v2/requests', {
      method: 'POST',
      headers: {
        Authorization: `Basic ${authorization}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 201) {
          onCancel();
        } else {
          setToast(true);
          setTimeout(() => setToast(false), 3000);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.message);
        setToast(true);
        setTimeout(() => setToast(false), 3000);
      });
  };

  const isDisabled = !message.length;

  return (
    <div style={mainContainerStyle}>
      <div style={containerStyle}>
        {isLoading && <Loading isVisible={isLoading} />}
        {isToast && <ToastBar isVisible={isToast} message="Something went wrong, try again later." />}
        <span style={styles.label}>Name:</span>
        <Input onError={false} onChange={handleNoChange} errorMessage="" placeholder={name} editable />
        <Separator size="S" />
        <span style={styles.label}>Email:</span>
        <Input onError={false} onChange={handleNoChange} errorMessage="" placeholder={email} editable />
        <Separator size="S" />
        <span style={styles.label}>Message:</span>
        <textarea style={styles.textarea} onChange={handleMessageChange} />
        <Separator size="M" />
        <Button onClick={handleSend} width="100%" type={!isDisabled ? 'PRIMARY' : 'DISABLED'} title="Send message" disabled={isDisabled} />
        <Separator size="S" />
        <PressableText onClick={onCancel} width="100%" title="Cancel" textAlign="center" />
      </div>
    </div>
  );
};
