import React, { FC } from 'react';
import { COLORS } from '../../main/utils/Colors';

interface ShareIconProps {
  width: number;
  height: number;
  color: COLORS;
}

export const ShareIcon: FC<ShareIconProps> = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.95727 16.4542C6.85933 16.4542 8.40124 14.9123 8.40124 13.0103C8.40124 11.1082 6.85933 9.5663 4.95727 9.5663C3.05522 9.5663 1.51331 11.1082 1.51331 13.0103C1.51331 14.9123 3.05522 16.4542 4.95727 16.4542Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M21.0428 8.4012C22.9449 8.4012 24.4868 6.85928 24.4868 4.95723C24.4868 3.05518 22.9449 1.51326 21.0428 1.51326C19.1408 1.51326 17.5989 3.05518 17.5989 4.95723C17.5989 6.85928 19.1408 8.4012 21.0428 8.4012Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M21.0428 24.4867C22.9449 24.4867 24.4868 22.9448 24.4868 21.0428C24.4868 19.1407 22.9449 17.5988 21.0428 17.5988C19.1408 17.5988 17.5989 19.1407 17.5989 21.0428C17.5989 22.9448 19.1408 24.4867 21.0428 24.4867Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M8.01636 11.4346L17.9685 6.50214" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M7.98547 14.6424L17.953 19.5287" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
  </svg>

);
