import React, { FC, useState } from 'react';
import { COLORS } from '../utils/Colors';

interface NumberSliderProps {
  slide: number;
  isError?: boolean
}

export const NumberSlider: FC<NumberSliderProps> = ({ slide, isError }) => {
  NumberSlider.defaultProps = {
    isError: false,
  };

  const [slideOne, setSlideOne] = useState<boolean>(false);
  const [slideTwo, setSlideTwo] = useState<boolean>(false);
  const [slideThree, setSlideThree] = useState<boolean>(false);
  const [slideFour, setSlideFour] = useState<boolean>(false);

  const sliderItemEnabled = { backgroundColor: COLORS.BLUE };
  const sliderFourthItemEnabled = { backgroundColor: isError ? COLORS.RED : COLORS.BLUE };
  const sliderItemDisabled = { backgroundColor: COLORS.BLUE_50 };

  const arrowEnabled = { color: COLORS.BLUE };
  const arrowDisabled = { color: COLORS.BLUE_50 };

  useState(() => {
    switch (slide) {
      case 1:
        setSlideOne(true);
        setSlideTwo(false);
        setSlideThree(false);
        setSlideFour(false);
        break;
      case 2:
        setSlideOne(true);
        setSlideTwo(true);
        setSlideThree(false);
        setSlideFour(false);
        break;
      case 3:
        setSlideOne(true);
        setSlideTwo(true);
        setSlideThree(true);
        setSlideFour(false);
        break;
      case 4:
        setSlideOne(true);
        setSlideTwo(true);
        setSlideThree(true);
        setSlideFour(true);
        break;
      default:
        setSlideOne(false);
        setSlideTwo(false);
        setSlideThree(false);
        setSlideFour(false);
        break;
    }
  });
  return (
    <div className="NumberSliderContainer">
      <div className="NumberSliderItem" style={slideOne ? sliderItemEnabled : sliderItemDisabled}>
        <span style={{ fontSize: 10 }}>1</span>
      </div>
      <i className="fa-solid fa-arrow-right" style={slideTwo ? arrowEnabled : arrowDisabled} />
      <div className="NumberSliderItem" style={slideTwo ? sliderItemEnabled : sliderItemDisabled}>
        <span style={{ fontSize: 10 }}>2</span>
      </div>
      <i className="fa-solid fa-arrow-right" style={slideThree ? arrowEnabled : arrowDisabled} />
      <div className="NumberSliderItem" style={slideThree ? sliderItemEnabled : sliderItemDisabled}>
        <span style={{ fontSize: 10 }}>3</span>
      </div>
      <i className="fa-solid fa-arrow-right" style={slideFour ? arrowEnabled : arrowDisabled} />
      <div className="NumberSliderItem" style={slideFour ? sliderFourthItemEnabled : sliderItemDisabled}>
        <span style={{ fontSize: 10 }}>4</span>
      </div>
    </div>
  );
};
