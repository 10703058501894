import React, { FC } from 'react';

import logo from '../../assets/logo_transparent.png';

interface LoadingProps {
  isVisible: boolean
}

export const Loading: FC<LoadingProps> = ({ isVisible }) => {
  const loadingVisibility = { zIndex: isVisible ? 1 : -1 };

  return (
    <div className="Loading" style={loadingVisibility}>
      <img className="App-logo" src={logo} alt="icon" width="10%" />
    </div>
  );
};
