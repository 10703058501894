import React, { ChangeEvent, CSSProperties, FC, useState } from 'react';
import { ModalContainer } from './ModalContainer';
import { Input } from '../Input';
import { Button } from '../Button';
import { ShareIcon } from '../../../assets/icons/ShareIcon';
import { COLORS } from '../../utils/Colors';
import { Separator } from '../Separator';
import { emailChecker } from '../../utils/InputChecker';
import { PressableText } from '../PressableText';
import { API } from '../../utils/API';
import { Loading } from '../Loading';
import { ToastBar } from '../ToastBar';

interface ShareModalProps {
  onCancel: () => void;
}

export const ShareModal: FC<ShareModalProps> = ({ onCancel }) => {
  const styles = {
    iconContainer: {
      width: 100,
      height: 100,
      background: COLORS.GREEN_2_OPACITY,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
    } as CSSProperties,
    body: {
      textAlign: 'center',
    } as CSSProperties,
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isToasVisible, setToastVisible] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setEmail(input);

    const isValid = emailChecker(input);
    if (!isValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };

  const handleShare = () => {
    setLoading(true);
    API.Users.share(email)
      .then(() => {
        setLoading(false);
        onCancel();
      })
      .catch((error) => {
        setLoading(false);
        setToastVisible(true);
        console.error(error);
        setTimeout(() => setToastVisible(false), 3000);
      });
  };

  const isDisabled = !email.length || emailError;

  return (
    <ModalContainer>
      {isLoading && <Loading isVisible={isLoading} />}
      {isToasVisible && <ToastBar isVisible={isToasVisible} message="Something went wrong, try again later." />}
      <div style={styles.iconContainer}>
        <ShareIcon width={60} height={60} color={COLORS.GREEN_2} />
      </div>
      <Separator size="M" />
      <span style={styles.body}>Invite others into the Olyns Community</span>
      <Separator size="S" />
      <Input onError={emailError} onChange={handleEmailChange} errorMessage="Write a valid email." placeholder="Email address" type="email" />
      <Separator size="S" />
      <Button onClick={handleShare} width="100%" type={!isDisabled ? 'PRIMARY' : 'DISABLED'} title="Submit" disabled={isDisabled} />
      <Separator size="S" />
      <PressableText onClick={onCancel} width="100%" title="Cancel" textAlign="center" />
    </ModalContainer>
  );
};
