import React, { CSSProperties, FC } from 'react';

type SeparatorSize = 'XS' | 'S' | 'M' | 'L';
type SeparatorOrientation = 'H' | 'V';

const sizes = {
  XS: 10,
  S: 20,
  M: 40,
  L: 60,
};

interface SeparatorProps {
  size: SeparatorSize;
  orientation?: SeparatorOrientation;
}

export const Separator: FC<SeparatorProps> = ({ size, orientation = 'V' }) => {
  Separator.defaultProps = {
    orientation: 'V',
  };

  const separatorStyle: CSSProperties = {
    width: orientation === 'V' ? 1 : sizes[size],
    height: orientation === 'V' ? sizes[size] : 1,
  };

  return <div style={separatorStyle} />;
};
