import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appWidth } from './CSD/WelcomeCSD';
import screenImage from '../../assets/images/welcome-pattern.png';
import { COLORS } from '../utils/Colors';
import logo from '../../assets/full-logo.svg';
import { AsideWidgets } from '../components/AsideWidgets';
import { Separator } from '../components/Separator';
import { API } from '../utils/API';
import { Profile } from '../models/Profile';
import { formatByTemplate } from '../helpers/date';
import { ProfileWidget } from '../components/ProfileWidget';
import { cleanAuthToken } from '../utils/APIConfig';
import { Router } from '../router/Router';
import { BalanceWidget } from '../components/BalanceWidget';
import { AuthProvider } from '../components/AuthProvider';
import { ShareModal } from '../components/modals/ShareModal';
import { SupportModal } from '../components/modals/SupportModal';
import { TutorialModal } from '../components/modals/TutorialModal';
import { AddSmartCardModal } from '../components/modals/AddSmartCardModal';

export const Home: FC = () => {
  const navigation = useNavigate();
  const styles = {
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      background: COLORS.DULL,
    } as CSSProperties,
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
    } as CSSProperties,
    bgImage: {
      height: appWidth ? '55%' : '60%',
      position: 'fixed',
      bottom: 5,
      right: 0,
    } as CSSProperties,
    aside: {
      width: 180,
      background: 'white',
      marginRight: 40,
      paddingBlock: 30,
      paddingLeft: 20,
    } as CSSProperties,
    logo: {
      width: '70%',
    } as CSSProperties,
    title: {
      fontSize: 24,
      marginTop: 30,
      fontWeight: 500,
    } as CSSProperties,
    dateBlue: {
      color: COLORS.BLUE,
      fontSize: 12,
      fontWeight: 500,
    } as CSSProperties,
    dateGray: {
      color: COLORS.LIGHT_GRAY,
    } as CSSProperties,
  };

  const [isHome, setHome] = useState<boolean>(true);
  const [isTutorial, setTutorial] = useState<boolean>(false);
  const [isSupport, setSupport] = useState<boolean>(false);
  const [isShare, setShare] = useState<boolean>(false);
  const [isCardOpen, setCardOpen] = useState<boolean>(false);
  const handleAsideHome = () => {
    if (!isHome) {
      setHome(true);
      setTutorial(false);
      setSupport(false);
      setShare(false);
      setCardOpen(false);
    }
  };
  const handleAsideTutorial = () => {
    if (!isTutorial) {
      setHome(false);
      setTutorial(true);
      setSupport(false);
      setShare(false);
      setCardOpen(false);
    }
  };
  const handleAsideSupport = () => {
    if (!isSupport) {
      setHome(false);
      setTutorial(false);
      setSupport(true);
      setShare(false);
      setCardOpen(false);
    }
  };
  const handleAsideShare = () => {
    if (!isShare) {
      setHome(false);
      setTutorial(false);
      setSupport(false);
      setShare(true);
      setCardOpen(false);
    }
  };
  const handleNFCCard = () => {
    if (!isCardOpen) {
      setProfileOpen(false);
      setCardOpen(true);
      setHome(false);
      setTutorial(false);
      setSupport(false);
      setShare(false);
    } else {
      setCardOpen(false);
    }
  };

  const [profile, setProfile] = useState<Profile>();
  const [date] = useState<string>(formatByTemplate(new Date(), 'dddd, DD MMMM YYYY'));

  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const handleOpenProfile = () => setProfileOpen(!profileOpen);
  const handleSignOut = () => {
    cleanAuthToken();
    Router(navigation).goToWelcome();
  };

  useEffect(() => {
    API.Users.profile()
      .then((profileResponse) => {
        setProfile(profileResponse);
      });
  }, []);

  return (
    <AuthProvider>
      <div style={styles.root}>
        <img style={styles.bgImage} src={screenImage} alt="background" />
        {isShare && <ShareModal onCancel={handleAsideHome} />}
        {isSupport && <SupportModal email={profile?.email} name={`${profile?.first_name} ${profile?.last_name}`} onCancel={handleAsideHome} />}
        {isTutorial && <TutorialModal onClose={handleAsideHome} />}
        {isCardOpen && <AddSmartCardModal onCancel={handleNFCCard} />}
        <ProfileWidget name={`${profile?.first_name} ${profile?.last_name}`} isOpen={profileOpen} handleOpen={handleOpenProfile} handleSignOut={handleSignOut} handleNFCCard={handleNFCCard} />
        <aside style={styles.aside}>
          <img style={styles.logo} src={logo} alt="logo" />
          <Separator size="M" />
          <AsideWidgets type="HOME" title="Home" isSelected={isHome} onClick={handleAsideHome} />
          <Separator size="XS" />
          <AsideWidgets type="TUTORIAL" title="Tutorial" isSelected={isTutorial} onClick={handleAsideTutorial} />
          <Separator size="XS" />
          <AsideWidgets type="SUPPORT" title="Support" isSelected={isSupport} onClick={handleAsideSupport} />
          <Separator size="XS" />
          <AsideWidgets type="SHARE" title="Share" isSelected={isShare} onClick={handleAsideShare} />
        </aside>
        <div style={styles.mainContainer}>
          <span style={styles.title}>Welcome {profile?.first_name}</span>
          <span style={styles.dateBlue}>{date.split(',')[0]}, <span style={styles.dateGray}>{date.split(',')[1]}</span></span>
          <Separator size="S" />
          <BalanceWidget />
        </div>
      </div>
    </AuthProvider>
  );
};
