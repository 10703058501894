import React, { FC } from 'react';
import { COLORS } from '../../main/utils/Colors';

interface ChatIconProps {
  width: number;
  height: number;
  color: COLORS;
}

export const ChatIcon: FC<ChatIconProps> = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.63135 10.704C3.63135 15.7796 8.10372 19.888 13.6266 19.888C14.7003 19.8879 15.7678 19.7266 16.7936 19.4095L21.6248 22.1866V16.2009C22.9105 14.6568 23.6184 12.7132 23.627 10.704C23.627 5.63359 19.1494 1.52004 13.6266 1.52004C8.10372 1.52004 3.63135 5.63359 3.63135 10.704Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.6314 10.704C2.98078 11.2097 2.45291 11.8559 2.08728 12.5944C1.72166 13.3328 1.52773 14.1444 1.52002 14.9684C1.52002 16.7105 1.86845 18.6087 3.15816 19.8412V24.48L7.61493 22.0201C8.58889 22.1285 9.56801 22.184 10.548 22.1866C11.8117 22.1866 13.9075 21.2401 15.2908 19.7632" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.70178 9.09187H20.1843" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.70178 13.6839H15.5872" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
