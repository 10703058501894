import React, { FC } from 'react';
import { COLORS } from '../../main/utils/Colors';

interface HomeIconProps {
  width: number;
  height: number;
  color: COLORS
}

export const HomeIcon: FC<HomeIconProps> = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 11.6421L11.558 1.14604C11.6042 1.09976 11.6591 1.06303 11.7196 1.03797C11.7801 1.0129 11.8451 1 11.9106 1C11.9762 1 12.0411 1.0129 12.1016 1.03797C12.1621 1.06303 12.217 1.09976 12.2632 1.14604L23 11.5138L20.7553 9.34149V19.1069C20.754 19.8738 20.447 20.6089 19.9015 21.1512C19.356 21.6935 18.6166 21.9987 17.8451 22H14.6966V14.2193C14.6966 14.0883 14.6443 13.9627 14.5512 13.8702C14.458 13.7776 14.3317 13.7256 14.2 13.7256H9.97381C9.8421 13.7256 9.71579 13.7776 9.62266 13.8702C9.52952 13.9627 9.4772 14.0883 9.4772 14.2193V22H6.52731C5.7559 21.9987 5.01645 21.6935 4.47097 21.1512C3.9255 20.6089 3.61847 19.8738 3.61716 19.1069V9.04527" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
