import React, { CSSProperties, FC, useState } from 'react';
import cashoutImage from '../../../assets/images/cashout.png';
import { ModalContainer } from './ModalContainer';
import { Button } from '../Button';
import { PressableText } from '../PressableText';
import { Separator } from '../Separator';
import { COLORS } from '../../utils/Colors';
import { Loading } from '../Loading';
import { API } from '../../utils/API';

interface CashOutModalProps {
  balance: number
  handleCancel: () => void;
  handleCashoutSuccess: (value: boolean) => void;
  handleCashoutError: (value: boolean) => void;
}

export const CashOutModal: FC<CashOutModalProps> = ({ balance, handleCancel, handleCashoutSuccess, handleCashoutError }) => {
  const styles = {
    image: {
      width: '45%',
    } as CSSProperties,
    title: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 10,
    } as CSSProperties,
    body: {
      textAlign: 'center',
      color: COLORS.LIGHT_GRAY,
    } as CSSProperties,
  };

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleCashOut = () => {
    setLoading(true);
    API.Users.withdrawal(balance)
      .then(() => {
        setLoading(false);
        handleCashoutSuccess(true);
        handleCashoutError(false);
        handleCancel();
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
        handleCashoutSuccess(false);
        handleCashoutError(true);
        handleCancel();
      });
  };
  
  return (
    <>
      {isLoading && <Loading isVisible={isLoading} />}
      <ModalContainer>
        <img style={styles.image} src={cashoutImage} alt="cashout" />
        <Separator size="S" />
        <span style={styles.title}>Cash out</span>
        <span style={styles.body}>Are you sure you want to cash out now your balance?</span>
        <Separator size="M" />
        <Button onClick={handleCashOut} width="100%" type="PRIMARY" title="Cash out now" />
        <Separator size="S" />
        <PressableText onClick={handleCancel} width="100%" title="Cancel" textAlign="center" />
      </ModalContainer>
    </>
  );
};
