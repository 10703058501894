import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Router } from '../../router/Router';
import { COLORS } from '../../utils/Colors';
import { API } from '../../utils/API';
import { Store, StoresResponse } from '../../models/StoresResponse';
import { ToastBar } from '../../components/ToastBar';
import { checkStore } from '../../helpers/CheckStore';
import { Loading } from '../../components/Loading';
import { CSDProvider } from '../../components/CSDProvider';

export const SetStore: FC = () => {
  const [selectedStore, setSelectedStore] = useState<Store>();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const handleErrorVisibility = () => {
    setTimeout(() => {
      setErrorVisible(false);
      setErrorMessage('');
    }, 2000);
  };

  const [isLoading, setLoading] = useState<boolean>(false);

  const router = Router(useNavigate());

  const handleGoBack = () => router.goToWelcomeCSD();

  const handleConfirm = () => {
    if (selectedStore !== undefined) {
      localStorage.setItem('store', JSON.stringify(selectedStore));
      router.goToWelcomeCSD();
    }
  };

  const handleSelectStore = (store: Store) => {
    // @ts-ignore
    document.getElementById('selectedStore').innerHTML = `${store.company} ${store.name}`;
    setSelectedStore(store);
  };

  const checkSelectedStore = () => {
    const store: Store = checkStore();
    if (store !== undefined) {
      handleSelectStore(store);
    }
    setLoading(false);
  };

  const retrieveStores = async () => {
    setLoading(true);
    try {
      return await API.CSD.getStores('');
    } catch (error) {
      console.error(error);
      // @ts-ignore
      setErrorMessage(error.message);
      setErrorVisible(true);
      handleErrorVisibility();
      setLoading(false);
      return error;
    }
  };

  const populateStores = (response: StoresResponse) => {
    let html = '';
    if (response.stores !== undefined) {
      // FOR EACH TO POPULATE THE LIST OF STORES.
      response.stores.forEach((store) => {
        html += '<li>'
        + `<button type="button" class="btn" style="width: 100%;" id="store-${store.id}">${store.company} ${store.name}</button>`
        + '</li>';
      });
      // @ts-ignore
      document.getElementById('storesDropdownContainer').innerHTML = html;

      // FOR EACH TO ADD AN ACTION TO EACH STORE.
      response.stores.forEach((store) => {
        // @ts-ignore
        document.getElementById(`store-${store.id}`).addEventListener('click', () => {
          handleSelectStore(store);
        });
      });

      checkSelectedStore();
    }
  };

  useEffect(() => {
    retrieveStores().then((response: any) => populateStores(response as StoresResponse));
  }, []);

  return (
    <CSDProvider>
      <div className="container-fluid background">
        <div className="row">
          <div className="col text-end">
            <button type="button" className="btn CloseIcon" onClick={handleGoBack}>
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col text-center MainContainer">
            <h1 className="Title">Set Store</h1>

            {/* Select store section */}
            <div className="row">
              <div className="col text-start CenterObjects">
                <div className="WhiteContainer">

                  <span style={{ color: COLORS.BLUE }}>Store</span>

                  <div className="Select my-auto">
                    <p id="selectedStore" className="SelectOneStore my-auto" style={{ color: COLORS.DARK_GRAY, cursor: 'pointer' }} data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="true">
                      Select one of the stores
                    </p>
                    <i className="fa-solid fa-arrow-down my-auto" id="storeDropdown" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="true" style={{ color: COLORS.BLUE, cursor: 'pointer' }} />

                    <ul className="dropdown-menu dropdown-menu-end StoreDropdown" aria-labelledby="storeDropdown" id="storesDropdownContainer">
                      {/* POPULATED FROM FUNCTION */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row CenterObjects">
              <button type="button" className="btn ConfirmButton" onClick={handleConfirm}>Confirm</button>
            </div>

          </div>
        </div>
        <ToastBar isVisible={errorVisible} message={errorMessage} />
        <Loading isVisible={isLoading} />
      </div>
    </CSDProvider>
  );
};
