import React, { CSSProperties, FC } from 'react';
import depositIcon from '../../../../assets/images/recycle-bubble.png';
import { COLORS } from '../../../utils/Colors';

export const TutorialSlideTwo: FC = () => {
  const styles = {
    root: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    } as CSSProperties,
    image: {
      width: '35%',
    } as CSSProperties,
    title: {
      width: '100%',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 700,
      marginTop: 10,
    } as CSSProperties,
    body: {
      width: '100%',
      textAlign: 'center',
      marginBlock: 10,
      color: COLORS.LIGHT_GRAY,
    } as CSSProperties,
  };

  return (
    <div style={styles.root}>
      <img src={depositIcon} alt="NFC" style={styles.image} />
      <span style={styles.title}>Deposit</span>
      <span style={styles.body}>Deposit your containers one at a time to earn cash from your CRV containers.</span>
    </div>
  );
};
