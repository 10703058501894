import React, { FC } from 'react';
import { COLORS } from '../../main/utils/Colors';

interface ProfileIconProps {
  width: number;
  height: number;
  color: COLORS
}

export const ProfileIcon: FC<ProfileIconProps> = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.413 14.2391H6.93478C5.48186 14.2391 4.08844 14.8163 3.06107 15.8437C2.03369 16.8711 1.45652 18.2645 1.45652 19.7174V20.6304C1.45652 20.6304 4.30978 21.5435 9.67391 21.5435C15.038 21.5435 17.8913 20.6304 17.8913 20.6304V19.7174C17.8913 18.2645 17.3141 16.8711 16.2868 15.8437C15.2594 14.8163 13.866 14.2391 12.413 14.2391Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.1087 6.02174C5.1087 4.81097 5.58967 3.64979 6.44582 2.79365C7.30196 1.9375 8.46314 1.45653 9.67391 1.45653C10.8847 1.45653 12.0459 1.9375 12.902 2.79365C13.7582 3.64979 14.2391 4.81097 14.2391 6.02174C14.2391 8.54174 12.1939 11.5 9.67391 11.5C7.15391 11.5 5.1087 8.54174 5.1087 6.02174Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
