import React, { ChangeEvent, CSSProperties, useState, KeyboardEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import screenImage from '../../assets/images/welcome-pattern.png';
import { appWidth } from './CSD/WelcomeCSD';
import { Heading } from '../components/Heading';
import { Router } from '../router/Router';
import { Input } from '../components/Input';
import { Separator } from '../components/Separator';
import { Button } from '../components/Button';
import { PressableText } from '../components/PressableText';
import { emailChecker } from '../utils/InputChecker';
import { doLogin } from '../utils/APIUtils';
import { Loading } from '../components/Loading';
import { ToastBar } from '../components/ToastBar';
import { NotAuthProvider } from '../components/NotAuthProvider';

export const SignIn: React.FC = () => {
  const navigation = useNavigate();

  const styles = {
    root: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
    } as CSSProperties,
    bgImage: {
      height: appWidth ? '55%' : '60%',
      position: 'fixed',
      bottom: 5,
      right: 0,
    } as CSSProperties,
    mainContainer: {
      paddingBlock: 20,
      width: 400,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: appWidth ? 30 : 100,
      marginTop: appWidth ? 50 : 0,
    } as CSSProperties,
    form: {
      width: '100%',
      display: 'flex',
      flex: appWidth ? 'unset' : 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    } as CSSProperties,
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isToasVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');

  const handleBackPressed = () => Router(navigation).goToWelcome();

  const [emailError, setEmailError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    const isValid = emailChecker(event.target.value);
    if (!isValid) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const buttonDisabled = !email.length || !password.length;

  const handleDone = () => {
    setLoading(true);
    if (email && password) {
      doLogin(email, password)
        .then((profile) => {
          setLoading(false);
          if (profile?.is_csd_representative) {
            Router(navigation).goToWelcomeCSD();
            return;
          }
          Router(navigation).goToHome();
        })
        .catch((error) => {
          console.log(error);
          if (error.errorCode) {
            setToastMessage(error.message);
            setToastVisible(true);
            setLoading(false);
            setTimeout(() => setToastVisible(false), 3000);
            return;
          }
          setToastMessage('Your email or password is incorrect');
          setToastVisible(true);
          setLoading(false);
          setTimeout(() => setToastVisible(false), 3000);
        });
    }
  };
  const handleForgotPassword = () => Router(navigation).goToPasswordRecovery();
  const handleVerificationEmail = () => Router(navigation).goToEmailVerificationResend();

  const enterKeyPressed = (event: KeyboardEventHandler<HTMLInputElement>) => {
    // @ts-ignore
    const pressed = event.key.toLowerCase();
    if (pressed === 'enter' && !buttonDisabled) {
      handleDone();
    }
  };

  return (
    <NotAuthProvider>
      <img style={styles.bgImage} src={screenImage} alt="background" />
      <div style={styles.root}>
        {isLoading && <Loading isVisible={isLoading} />}
        {isToasVisible && <ToastBar isVisible={isToasVisible} message={toastMessage} />}
        <div style={styles.mainContainer}>
          <Heading title="Sign in" onBack={handleBackPressed} />
          <div style={styles.form}>
            {appWidth ? <Separator size="L" /> : <></>}
            <Input placeholder="Email Address" onError={emailError} errorMessage="Enter a valid email." onChange={handleEmailChange} type="email" />
            <Separator size="S" />
            <Input placeholder="Password" onError={false} errorMessage="" onChange={handlePasswordChange} type="password" onKeyPressed={enterKeyPressed} />
            <Separator size="L" />
            <Button type={buttonDisabled ? 'DISABLED' : 'PRIMARY'} width="100%" title="Done" onClick={handleDone} disabled={buttonDisabled} />
            <Separator size="M" />
            <PressableText onClick={handleVerificationEmail} width="100%" title="Can't find the verification email?" textAlign="center" />
            <Separator size="S" />
            <PressableText onClick={handleForgotPassword} width="100%" title="Forgot your password?" textAlign="center" />
          </div>
        </div>
      </div>
    </NotAuthProvider>
  );
};
