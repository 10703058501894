export enum COLORS {
  BLUE = '#4bb1f8',
  BLUE_OPACITY = 'rgba(75,177,248,0.2)',
  BLUE_50 = 'rgba(75,177,248,0.5)',
  DARK_GRAY = '#536474',
  LIGHT_GRAY = '#989898',
  VERY_LIGHT_GRAY = '#BDBDBD',
  DULL = '#F5F7FF',
  GREEN = '#61C5C0',
  GREEN_2 = '#66DA51',
  GREEN_2_OPACITY = 'rgba(102,218,81,0.2)',
  LIGHT_GREEN = '#6FD19D',
  LIGHT_GREEN_2 = '#5CBCB6',
  TRANSLUCENT_GREEN = 'rgba(111,209,157,0.2)',
  RED = '#ff0000',
  RED_VARIANT = '#ff9e9e',
  RED_OPACITY = 'rgba(255,0,0,0.2)',
  TRANSLUCENT_RED = 'rgba(255,0,0,0.2)'
}
