import React, { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Router } from '../router/Router';
import { getAuthToken } from '../utils/APIConfig';
import { API } from '../utils/API';

interface NotAuthProviderProps {
  children: ReactNode;
}

export const NotAuthProvider: FC<NotAuthProviderProps> = ({ children }) => {
  const navigation = useNavigate();

  const handleUser = () => Router(navigation).goToHome();
  const handleCSD = () => Router(navigation).goToWelcomeCSD();

  useEffect(() => {
    const token = getAuthToken();
    if (token != null) {
      API.Users.profile()
        .then((profile) => {
          if (profile?.is_csd_representative) {
            handleCSD();
            return;
          }
          handleUser();
        });
    }
  }, []);
  return (
    <div>
      {children}
    </div>
  );
};
