import React, { FC } from 'react';
import { COLORS } from '../utils/Colors';

interface SuccessCashOutProps {
  onClick: () => void
}

export const SuccessCashOut: FC<SuccessCashOutProps> = ({ onClick }) => (
  <div className="WhiteContainerFlex">
    <div className="SmallRoundContainer" style={{ backgroundColor: COLORS.TRANSLUCENT_GREEN, color: COLORS.LIGHT_GREEN }}>
      <i className="fa-solid fa-check" />
    </div>
    <h3 style={{ fontWeight: 'bold', color: COLORS.DARK_GRAY, marginTop: 20 }}>Awesome!</h3>
    <p style={{ color: COLORS.DARK_GRAY }}>Great job! Keep recycling to earn more cash.</p>
    <button type="button" className="btn GreenLightConfirmButton" style={{ width: 300 }} onClick={onClick}>Accept</button>
  </div>
);
