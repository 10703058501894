import React, { FC } from 'react';
import { COLORS } from '../../main/utils/Colors';

interface SignOutIconProps {
  width: number;
  height: number;
  color: COLORS
}

export const SignOutIcon: FC<SignOutIconProps> = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.2812 13.25V14.5C12.2812 15.8785 11.1598 17 9.78125 17H3.5C2.12146 17 1 15.8785 1 14.5V3.49998C1 2.12144 2.12146 0.999985 3.5 0.999985H9.78125C11.1598 0.999985 12.2812 2.12144 12.2812 3.49998V4.74998C12.2812 5.0952 12.0015 5.37498 11.6562 5.37498C11.311 5.37498 11.0312 5.0952 11.0312 4.74998V3.49998C11.0312 2.81078 10.4705 2.24998 9.78125 2.24998H3.5C2.81079 2.24998 2.25 2.81078 2.25 3.49998V14.5C2.25 15.1892 2.81079 15.75 3.5 15.75H9.78125C10.4705 15.75 11.0312 15.1892 11.0312 14.5V13.25C11.0312 12.9048 11.311 12.625 11.6562 12.625C12.0015 12.625 12.2812 12.9048 12.2812 13.25ZM16.5424 7.92638L15.1428 6.52684C14.8987 6.2827 14.5029 6.2827 14.2589 6.52684C14.0148 6.77086 14.0148 7.16661 14.2589 7.41063L15.2544 8.40623H7.75C7.40479 8.40623 7.125 8.68602 7.125 9.03123C7.125 9.37645 7.40479 9.65623 7.75 9.65623H15.2544L14.2589 10.6518C14.0148 10.8959 14.0148 11.2916 14.2589 11.5356C14.381 11.6577 14.5409 11.7187 14.7008 11.7187C14.8608 11.7187 15.0208 11.6577 15.1428 11.5356L16.5424 10.1361C17.1516 9.52684 17.1516 8.53563 16.5424 7.92638Z" fill={color} stroke={color} strokeWidth="0.5" />
  </svg>

);
