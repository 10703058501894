import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Router } from '../../router/Router';
import { NumberSlider } from '../../components/NumberSlider';
import { SuccessCashOut } from '../../components/SuccessCashOut';
import { ErrorCashOut } from '../../components/ErrorCashOut';
import { Store } from '../../models/StoresResponse';
import { checkStore } from '../../helpers/CheckStore';
import { formatByTemplate } from '../../helpers/date';
import { CSDProvider } from '../../components/CSDProvider';

export const CashOutResult: FC = () => {
  const router = Router(useNavigate());
  const location = useLocation();

  const [selectedStore] = useState<Store>(checkStore());

  // @ts-ignore
  const [isError] = useState<boolean>(location.state.isError);
  // @ts-ignore
  const [errorMessage] = useState<string>(location.state.errorMessage);

  const handleSuccess = () => {
    localStorage.removeItem('user');
    router.goToWelcomeCSD();
  };

  const handleError = () => router.goToCustomerConfirmation();

  const checkSelectedStore = () => {
    if (selectedStore === undefined) {
      router.goToStore();
    }
  };

  useEffect(() => {
    checkSelectedStore();
  }, []);

  return (
    <CSDProvider>
      <div className="container-fluid-background">
        <div className="row Navbar">
          <div className="col my-auto">
            <p className="my-auto" style={{ color: 'white' }}>
              <span style={{ fontWeight: 'bold' }}>OLYNS</span> - {`${selectedStore?.company} ${selectedStore?.name}`.toUpperCase()}
            </p>
          </div>
          <div className="col my-auto text-center">
            <p className="my-auto" style={{ color: 'white', fontWeight: 'bold' }}>GET CASH</p>
          </div>
          <div className="col my-auto text-end">
            <p className="my-auto" style={{ color: 'white' }}>{formatByTemplate(new Date(), 'hh:mmA, MMMM DD').toUpperCase()}</p>
          </div>
        </div>

        <div className="row" style={{ height: '90vh', display: 'flex', alignItems: 'center' }}>
          <div className="col text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <NumberSlider slide={4} isError={isError} />

            {
              isError
                ? <ErrorCashOut onClick={handleError} errorMessage={errorMessage} />
                : <SuccessCashOut onClick={handleSuccess} />
            }

          </div>
        </div>

      </div>
    </CSDProvider>
  );
};
