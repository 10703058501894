import React, { CSSProperties, FC, useState } from 'react';
import { ModalContainer } from './ModalContainer';
import { TutorialSlideOne } from './TutorialModalSlides/TutorialSlideOne';
import logo from '../../../assets/full-logo.svg';
import { Button } from '../Button';
import { PressableText } from '../PressableText';
import { Separator } from '../Separator';
import { TutorialSlideTwo } from './TutorialModalSlides/TutorialSlideTwo';
import { TutorialSlideThree } from './TutorialModalSlides/TutorialSlideThree';

interface TutorialModal {
  onClose: () => void;
}

export const TutorialModal: FC<TutorialModal> = ({ onClose }) => {
  const styles = {
    root: {
      height: 400,
    } as CSSProperties,
    logo: {
      width: '30%',
    } as CSSProperties,
  };

  const [slideIndex, setSlideIndex] = useState<number>(0);
  const slides = [
    <TutorialSlideOne />,
    <TutorialSlideTwo />,
    <TutorialSlideThree />,
  ];

  const handleNext = () => {
    switch (slideIndex) {
      case 0:
        setSlideIndex(1);
        break;
      case 1:
        setSlideIndex(2);
        break;
      default:
        onClose();
        break;
    }
  };
  const handleVideo = () => window.open('https://www.olyns.com/app/user-tutorial', '_blank', 'noopener,noreferrer');
  const nextLabel = slideIndex === (slides.length - 1) ? 'Done' : 'Next';
  return (
    <ModalContainer>
      <img style={styles.logo} src={logo} alt="logo" />
      <Separator size="XS" />
      <span>Earn Cash for your CRV containers</span>
      <Separator size="XS" />
      <div style={styles.root}>
        {slides[slideIndex]}
      </div>
      <Button onClick={handleNext} width="100%" type="PRIMARY" title={nextLabel} />
      <Separator size="S" />
      <PressableText onClick={handleVideo} width="100%" title="Check video tutorial" textAlign="center" />
    </ModalContainer>
  );
};
