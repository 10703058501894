import React, { CSSProperties, FC } from 'react';
import cashoutIcon from '../../../../assets/images/cashout.png';
import paypalLogo from '../../../../assets/images/paypal-logo.png';
import { COLORS } from '../../../utils/Colors';

export const TutorialSlideThree: FC = () => {
  const styles = {
    root: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    } as CSSProperties,
    image: {
      width: '35%',
    } as CSSProperties,
    paypal: {
      width: '25%',
    } as CSSProperties,
    title: {
      width: '100%',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 700,
      marginTop: 10,
    } as CSSProperties,
    body: {
      width: '100%',
      textAlign: 'center',
      marginTop: 10,
      color: COLORS.LIGHT_GRAY,
    } as CSSProperties,
  };

  return (
    <div style={styles.root}>
      <img src={cashoutIcon} alt="NFC" style={styles.image} />
      <span style={styles.title}>Cash out</span>
      <span style={styles.body}>Cash out your earnings with</span>
      <img src={paypalLogo} alt="Paypal" style={styles.paypal} />
    </div>
  );
};
