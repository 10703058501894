import React, { CSSProperties, FC } from 'react';
import { ModalContainer } from './ModalContainer';
import cashoutImage from '../../../assets/images/cashout.png';
import { Separator } from '../Separator';
import { Button } from '../Button';
import { COLORS } from '../../utils/Colors';

interface CashoutSuccessModalProps {
  handleClose: (value: boolean) => void;
}

export const CashoutSuccessModal: FC<CashoutSuccessModalProps> = ({ handleClose }) => {
  const styles = {
    image: {
      width: '45%',
    } as CSSProperties,
    title: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 10,
    } as CSSProperties,
    body: {
      textAlign: 'center',
      color: COLORS.LIGHT_GRAY,
    } as CSSProperties,
  };

  const handleDone = () => handleClose(false);

  return (
    <ModalContainer>
      <img style={styles.image} src={cashoutImage} alt="cashout" />
      <Separator size="S" />
      <span style={styles.title}>Success!</span>
      <span style={styles.body}>Your Olyns Cash Out is complete. Check your email for a payment confirmation from PayPal.</span>
      <Separator size="M" />
      <Button onClick={handleDone} width="100%" type="PRIMARY" title="Done!" />
    </ModalContainer>
  );
};
