import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Router } from '../../router/Router';
import { NumberSlider } from '../../components/NumberSlider';
import { Store } from '../../models/StoresResponse';
import { checkStore } from '../../helpers/CheckStore';
import { formatByTemplate } from '../../helpers/date';
import { User } from '../../models/User';
import { CSDProvider } from '../../components/CSDProvider';

export const RepresentativeConfirmation: FC = () => {
  const router = Router(useNavigate());

  const [selectedStore] = useState<Store>(checkStore());
  const [user, setUser] = useState<User>();

  const handleConfirm = () => router.goToCustomerConfirmation();
  const handleCancel = () => router.goToBalance();

  const checkSelectedStore = () => {
    if (selectedStore === undefined) {
      router.goToStore();
      return;
    }
    const retrievedUser = localStorage.getItem('user');
    if (retrievedUser !== null) {
      setUser(JSON.parse(retrievedUser));
    }
  };

  useEffect(() => {
    checkSelectedStore();
  }, []);

  return (
    <CSDProvider>
      <div className="container-fluid-background">
        <div className="row Navbar">
          <div className="col my-auto">
            <p className="my-auto" style={{ color: 'white' }}>
              <span style={{ fontWeight: 'bold' }}>OLYNS</span> - {`${selectedStore?.company} ${selectedStore?.name}`.toUpperCase()}
            </p>
          </div>
          <div className="col my-auto text-center">
            <p className="my-auto" style={{ color: 'white', fontWeight: 'bold' }}>GET CASH</p>
          </div>
          <div className="col my-auto text-end">
            <p className="my-auto" style={{ color: 'white' }}>{formatByTemplate(new Date(), 'hh:mmA, MMMM DD').toUpperCase()}</p>
          </div>
        </div>

        <div className="row" style={{ height: '90vh', display: 'flex', alignItems: 'center' }}>
          <div className="col text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <NumberSlider slide={2} />

            <div className="WhiteContainerNoPadding">
              <div className="GrayBG">
                <span>AS A CUSTOMER SERVICE</span>
                <br />
                <span>REPRESENTATIVE, I PAID...</span>
              </div>
              <p className="Balance">${user?.balance.toFixed(2)}</p>
            </div>

            <button type="button" className="btn AcceptButton" onClick={handleConfirm}>Confirm</button>
            <button type="button" className="btn CancelButton" onClick={handleCancel}>Cancel</button>
          </div>
        </div>

      </div>
    </CSDProvider>
  );
};
