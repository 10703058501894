import { NavigateFunction } from 'react-router-dom';

export interface EmailVerificationProps {
  email: string
}

export const Router = (navigate: NavigateFunction) => {
  const goToStore = () => navigate('/redemption-cash-3da5vj9xb2z/set-store', { replace: true });
  const goToWelcomeCSD = () => navigate('/redemption-cash-3da5vj9xb2z/welcome', { replace: true });
  const goToBalance = () => navigate('/redemption-cash-3da5vj9xb2z/balance', { replace: true });
  const goToRepresentativeConfirmation = () => navigate('/redemption-cash-3da5vj9xb2z/representative-confirmation', { replace: true });
  const goToCustomerConfirmation = () => navigate('/redemption-cash-3da5vj9xb2z/customer-confirmation', { replace: true });
  const goToCashOutResult = (isError: boolean, errorMessage: string) => navigate('/redemption-cash-3da5vj9xb2z/cash-out-result', { replace: true, state: { isError, errorMessage } });
  const goToWelcome = () => navigate('/welcome', { replace: true });
  const goToSignIn = () => navigate('/sign-in', { replace: true });
  const goToSignUp = () => navigate('/sign-up', { replace: true });
  const goToEmailVerification = (email: string) => navigate('/email-verification', { replace: true, state: { email } });
  const goToPasswordRecovery = () => navigate('/password-recovery', { replace: true });
  const goToEmailVerificationResend = () => navigate('/email-verification-resend', { replace: true });
  const goToHome = () => navigate('/home', { replace: true });

  return {
    goToStore,
    goToWelcomeCSD,
    goToBalance,
    goToRepresentativeConfirmation,
    goToCustomerConfirmation,
    goToCashOutResult,
    goToWelcome,
    goToSignIn,
    goToSignUp,
    goToEmailVerification,
    goToPasswordRecovery,
    goToEmailVerificationResend,
    goToHome,
  };
};
