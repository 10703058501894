import React, { CSSProperties, FC } from 'react';
import { HomeIcon } from '../../assets/icons/HomeIcon';
import { COLORS } from '../utils/Colors';
import { BottleIcon } from '../../assets/icons/BottleIcon';
import { ChatIcon } from '../../assets/icons/ChatIcon';
import { ShareIcon } from '../../assets/icons/ShareIcon';

type WidgetType = 'HOME' | 'TUTORIAL' | 'SUPPORT' | 'SHARE';

const WidgetColor = {
  HOME: COLORS.BLUE,
  TUTORIAL: COLORS.LIGHT_GREEN_2,
  SUPPORT: COLORS.LIGHT_GREEN,
  SHARE: COLORS.GREEN_2,
};

const WidgetIcon = {
  HOME: <HomeIcon width={24} height={24} color={WidgetColor.HOME} />,
  TUTORIAL: <BottleIcon width={24} height={24} color={WidgetColor.TUTORIAL} />,
  SUPPORT: <ChatIcon width={24} height={24} color={WidgetColor.SUPPORT} />,
  SHARE: <ShareIcon width={24} height={24} color={WidgetColor.SHARE} />,
};

interface AsideWidgetsProps {
  type: WidgetType;
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

export const AsideWidgets: FC<AsideWidgetsProps> = ({ type, title, isSelected, onClick }) => {
  const styles = {
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    } as CSSProperties,
    text: {
      flex: 1,
      marginLeft: 15,
      color: isSelected ? WidgetColor[type] : COLORS.DARK_GRAY,
    } as CSSProperties,
    highlight: {
      height: 45,
      width: 5,
      background: isSelected ? WidgetColor[type] : 'white',
    } as CSSProperties,
  };

  return (
    <div style={styles.root} onClick={onClick} className={!isSelected ? 'aside-widget' : ''}>
      {WidgetIcon[type]}
      <span style={styles.text}>{title}</span>
      <div style={styles.highlight} />
    </div>
  );
};
