import React, { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Router } from '../router/Router';
import { getAuthToken } from '../utils/APIConfig';
import { API } from '../utils/API';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const navigation = useNavigate();

  const handleNotUser = () => Router(navigation).goToWelcome();

  useEffect(() => {
    const token = getAuthToken();
    if (token == null) {
      handleNotUser();
      return;
    }
    API.Users.profile()
      .then((profile) => {
        if (profile?.is_csd_representative) {
          Router(navigation).goToWelcomeCSD();
        }
      });
  }, []);
  return (
    <div>
      {children}
    </div>
  );
};
