import React, { CSSProperties, FC } from 'react';
import { ModalContainer } from './ModalContainer';
import { Separator } from '../Separator';
import { Button } from '../Button';
import { COLORS } from '../../utils/Colors';
import { XMarkIcon } from '../../../assets/icons/XMarkIcon';
import { PressableText } from '../PressableText';

interface CashoutErrorModalProps {
  handleClose: (value: boolean) => void;
  handleRetry: (value: boolean) => void;
}

export const CashoutErrorModal: FC<CashoutErrorModalProps> = ({ handleClose, handleRetry }) => {
  const styles = {
    iconContainer: {
      background: COLORS.RED_OPACITY,
      padding: 30,
      borderRadius: 80,
    } as CSSProperties,
    title: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 10,
    } as CSSProperties,
    body: {
      textAlign: 'center',
      color: COLORS.LIGHT_GRAY,
    } as CSSProperties,
  };

  const handleCancel = () => handleClose(false);
  const handleInnerRetry = () => {
    handleRetry(true);
    handleCancel();
  };

  return (
    <ModalContainer>
      <div style={styles.iconContainer}>
        <XMarkIcon width={50} height={50} color={COLORS.RED} />
      </div>
      <Separator size="S" />
      <span style={styles.title}>Whoops!</span>
      <span style={styles.body}>Something went wrong, please try again later.</span>
      <Separator size="M" />
      <Button onClick={handleInnerRetry} width="100%" type="PRIMARY" title="Retry" />
      <Separator size="S" />
      <PressableText onClick={handleCancel} width="100%" title="Cancel" textAlign="center" />
    </ModalContainer>
  );
};
