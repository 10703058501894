import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Button } from './Button';
import { Separator } from './Separator';
import { COLORS } from '../utils/Colors';
import { API } from '../utils/API';
import { CashOutModal } from './modals/CashOutModal';
import { CashoutSuccessModal } from './modals/CashoutSuccessModal';
import { CashoutErrorModal } from './modals/CashoutErrorModal';
import { getAuthToken } from '../utils/APIConfig';

export const BalanceWidget: FC = () => {
  const styles = {
    root: {
      background: 'white',
      padding: 20,
      borderRadius: 10,
      width: 380,
      display: 'flex',
      alignItems: 'center',
      boxShadow: `0 0 10px ${COLORS.VERY_LIGHT_GRAY}`,
    } as CSSProperties,
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    } as CSSProperties,
    title: {
      fontSize: 22,
      fontWeight: 600,
    } as CSSProperties,
    balance: {
      fontSize: 40,
      fontWeight: 500,
    } as CSSProperties,
  };

  const [cashoutSuccess, setCashoutSuccess] = useState<boolean>(false);
  const [cashoutError, setCashoutError] = useState<boolean>(false);

  const [balance, setBalance] = useState<number>(0.00);
  const [cashoutModalOpen, setCashoutModalOpen] = useState<boolean>(false);

  const handleCashOut = () => setCashoutModalOpen(!cashoutModalOpen);
  const handleCashOutSuccess = (value: boolean) => {
    setCashoutSuccess(value);
    getBalance();
  };

  const buttonDisabled = balance > 0;

  const getBalance = () => {
    API.Users.balance()
      .then((response) => {
        setBalance(response.balance);
      });
  };

  useEffect(() => {
    getBalance();
    setInterval(() => {
      const authToken = getAuthToken();
      if (authToken != null) {
        getBalance();
      }
    }, 10000);
  }, []);
  return (
    <>
      {cashoutModalOpen && <CashOutModal balance={balance} handleCancel={handleCashOut} handleCashoutSuccess={handleCashOutSuccess} handleCashoutError={setCashoutError} />}
      {cashoutSuccess && <CashoutSuccessModal handleClose={setCashoutSuccess} />}
      {cashoutError && <CashoutErrorModal handleClose={setCashoutError} handleRetry={handleCashOut} />}
      <div style={styles.root}>
        <div style={styles.container}>
          <span style={styles.title}>My balance</span>
          <span style={styles.balance}>${balance.toFixed(2)}</span>
        </div>
        <Separator size="M" orientation="H" />
        <div style={styles.container}>
          <Button onClick={handleCashOut} width="100%" type={!buttonDisabled ? 'DISABLED' : 'PRIMARY'} title="Cash out" disabled={!buttonDisabled} />
        </div>
      </div>
    </>
  );
};
