import React, { FC } from 'react';
import { COLORS } from '../utils/Colors';

interface ToastBarProps {
  isVisible: boolean
  message: string
}

export const ToastBar: FC<ToastBarProps> = ({ isVisible = false, message }) => (
  <div style={{
    display: 'flex', justifyContent: 'center', position: 'fixed', top: 80, width: '100%', zIndex: isVisible ? 0 : -1,
  }}
  >
    <div style={{
      width: 300, backgroundColor: COLORS.RED_VARIANT, borderRadius: 5, paddingInline: 20, paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center',
    }}
    >
      <div style={{
        width: 30, height: 30, backgroundColor: COLORS.RED, borderRadius: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', color: COLORS.DULL, marginRight: 10,
      }}
      >
        <i className="fa-solid fa-exclamation" />
      </div>
      <span style={{ color: COLORS.DULL }}>{message}</span>
    </div>
  </div>
);
