import React, { ChangeEvent, CSSProperties, FC, useState } from 'react';
import { COLORS } from '../utils/Colors';

type InputType = 'text' | 'email' | 'password';

interface InputProps {
  placeholder?: string;
  onError: boolean;
  errorMessage: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: InputType;
  editable?: boolean;
  defValue?: string;
  onKeyPressed?: (event: any) => void;
}

export const Input: FC<InputProps> = ({ placeholder, onError, errorMessage, onChange, type = 'text', editable = false, defValue, onKeyPressed }) => {
  Input.defaultProps = {
    type: 'text',
    editable: false,
    placeholder: '',
    defValue: '',
    onKeyPressed: () => {},
  };

  const styles = {
    container: {
      width: '100%',
    } as CSSProperties,
    input: {
      width: '100%',
      border: 0,
      color: onError ? COLORS.RED : COLORS.DARK_GRAY,
      outline: 'none',
    } as CSSProperties,
    bottomLine: {
      width: '100%',
      height: 1,
      background: onError ? COLORS.RED : COLORS.LIGHT_GRAY,
      marginBlock: 10,
    } as CSSProperties,
    errorLabel: {
      color: COLORS.RED,
      fontSize: 14,
    } as CSSProperties,
    inputContainer: {
      width: '100%',
      display: 'flex',
    } as CSSProperties,
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const passwordIcon = showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye';
  const passwordControl = () => setShowPassword(!showPassword);
  const passwordType = showPassword ? 'text' : 'password';

  const inputType = type !== 'password' ? type : passwordType;

  return (
    <div style={styles.container}>
      <div style={styles.inputContainer}>
        <input type={inputType} style={styles.input} placeholder={placeholder} onChange={onChange} disabled={editable} defaultValue={defValue} onKeyPress={onKeyPressed} />
        {type === 'password' ? <button type="button" className="btn no-border-button" onClick={passwordControl}><i style={{ color: COLORS.LIGHT_GRAY }} className={passwordIcon} /></button> : <></>}
      </div>
      <div style={styles.bottomLine} />
      {onError && <span style={styles.errorLabel}>{errorMessage}</span>}
    </div>
  );
};
