import React, { FC } from 'react';
import { COLORS } from '../../main/utils/Colors';

interface ChevronRightIconProps {
  width: number;
  height: number;
  color: COLORS
}

export const ChevronRightIcon: FC<ChevronRightIconProps> = ({ width, height, color }) => (
  <svg width={width} height={height} viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.792932 15.2929L7.08581 9.00001L0.792932 2.7071C0.699147 2.61445 0.62461 2.50416 0.573606 2.3826C0.522601 2.26103 0.496138 2.13058 0.495737 1.99875C0.495337 1.86692 0.521008 1.73631 0.571274 1.61443C0.621539 1.49256 0.695406 1.38182 0.788626 1.2886C0.881845 1.19538 0.992579 1.12152 1.11445 1.07125C1.23633 1.02099 1.36694 0.995317 1.49877 0.995717C1.6306 0.996118 1.76105 1.02258 1.88262 1.07358C2.00419 1.12459 2.11447 1.19913 2.20712 1.29291L9.20712 8.29291C9.39465 8.48045 9.5 8.7348 9.5 9.00001C9.5 9.26522 9.39465 9.51956 9.20712 9.7071L2.20712 16.7071C2.0192 16.8928 1.76545 16.9965 1.50128 16.9957C1.23712 16.9949 0.984004 16.8896 0.797212 16.7028C0.610419 16.516 0.505125 16.2629 0.504323 15.9987C0.503521 15.7346 0.607276 15.4808 0.792932 15.2929Z" fill={color} />
  </svg>
);
