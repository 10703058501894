import React, { ChangeEvent, CSSProperties, FC, useEffect, useState } from 'react';
import { ModalContainer } from './ModalContainer';
import { Loading } from '../Loading';
import { ToastBar } from '../ToastBar';
import { COLORS } from '../../utils/Colors';
import { Separator } from '../Separator';
import { Input } from '../Input';
import { Button } from '../Button';
import { PressableText } from '../PressableText';
import { NFCIcon } from '../../../assets/icons/NFCIcon';
import { API } from '../../utils/API';
import { Profile } from '../../models/Profile';

interface AddSmartCardModalProps {
  onCancel: () => void;
}

export const AddSmartCardModal: FC<AddSmartCardModalProps> = ({ onCancel }) => {
  const styles = {
    iconContainer: {
      width: 100,
      height: 100,
      background: COLORS.BLUE_OPACITY,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
    } as CSSProperties,
    body: {
      textAlign: 'center',
    } as CSSProperties,
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isToastVisible, setToastVisible] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');

  const [profile, setProfile] = useState<Profile>();
  const [cardID, setCardID] = useState<string>('');
  const handleCardChange = (event: ChangeEvent<HTMLInputElement>) => setCardID(event.target.value);

  const handleSave = () => {
    if (profile) {
      if (cardID === profile.smart_card_nfc_id) {
        onCancel();
        return;
      }

      setLoading(true);
      if (cardID === '' && profile.smart_card_nfc_id !== '') {
        API.Users.deleteSmartCard(profile.smart_card_nfc_id)
          .then(() => {
            setLoading(false);
            onCancel();
          })
          .catch((deleteError) => {
            setLoading(false);
            setToastMessage(deleteError.message);
            setToastVisible(true);
            setTimeout(() => setToastVisible(false), 3000);
          });
        return;
      }

      API.Users.addSmartCard(cardID)
        .then(() => {
          setLoading(false);
          onCancel();
        })
        .catch((addError) => {
          setLoading(false);
          setToastMessage(addError.message);
          setToastVisible(true);
          setTimeout(() => setToastVisible(false), 3000);
        });
    }
  };

  useEffect(() => {
    API.Users.profile()
      .then((profileResponse) => {
        setProfile(profileResponse);
        setCardID(profileResponse.smart_card_nfc_id);
      });
  }, []);
  return (
    <ModalContainer>
      {isLoading && <Loading isVisible={isLoading} />}
      {isToastVisible && <ToastBar isVisible={isToastVisible} message={toastMessage} />}
      <div style={styles.iconContainer}>
        <NFCIcon width={60} height={60} color={COLORS.BLUE} />
      </div>
      <Separator size="M" />
      <Input onError={false} onChange={handleCardChange} errorMessage="" placeholder="Smart card ID" defValue={cardID} />
      <Separator size="S" />
      <Button onClick={handleSave} width="100%" type="PRIMARY" title="Save changes" />
      <Separator size="S" />
      <PressableText onClick={onCancel} width="100%" title="Cancel" textAlign="center" />
    </ModalContainer>
  );
};
