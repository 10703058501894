import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { COLORS } from '../../utils/Colors';

import logo from '../../../assets/full-logo.svg';
import { WelcomeWidget, WidgetType } from '../../components/WelcomeWidget';
import { Router } from '../../router/Router';
import { Store } from '../../models/StoresResponse';
import { checkStore } from '../../helpers/CheckStore';
import { formatByTemplate } from '../../helpers/date';
import { API } from '../../utils/API';
import { Loading } from '../../components/Loading';
import { ToastBar } from '../../components/ToastBar';
import { CSDProvider } from '../../components/CSDProvider';

export const appWidth = window.innerWidth <= 800;

export const WelcomeCSD: FC = () => {
  const router = Router(useNavigate());

  const [enteredText, setEnteredText] = useState('');
  const [selectedStore] = useState<Store>(checkStore());
  const [isLoading, setLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const handleErrorVisibility = () => {
    setTimeout(() => {
      setErrorVisible(false);
      setErrorMessage('');
    }, 2000);
  };

  const keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'shift+A', 'shift+B', 'shift+C', 'shift+D', 'shift+E', 'shift+F', 'shift+G', 'shift+H', 'shift+I', 'shift+J', 'shift+K', 'shift+L', 'shift+M', 'shift+N', 'shift+O', 'shift+P', 'shift+Q', 'shift+R', 'shift+S', 'shift+T', 'shift+U', 'shift+V', 'shift+W', 'shift+X', 'shift+Y', 'shift+Z',
    'shift+a', 'shift+b', 'shift+c', 'shift+d', 'shift+e', 'shift+f', 'shift+g', 'shift+h', 'shift+i', 'shift+j', 'shift+k', 'shift+l', 'shift+m', 'shift+n', 'shift+o', 'shift+p', 'shift+q', 'shift+r', 'shift+s', 'shift+t', 'shift+u', 'shift+v', 'shift+w', 'shift+x', 'shift+y', 'shift+z',
    'enter'];

  const goToSetStore = () => router.goToStore();

  const checkSelectedStore = () => {
    if (selectedStore === undefined) {
      router.goToStore();
    }
  };

  const login = (nfcID: string) => {
    setLoading(true);
    API.CSD.login(nfcID)
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response));
        setLoading(false);
        router.goToBalance();
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(error.message);
        setErrorVisible(true);
        handleErrorVisibility();
        setLoading(false);
      });
  };

  const keyDownHandler = (key: string, event: any) => {
    if (key === 'enter') {
      login(enteredText.toUpperCase());
      setEnteredText('');
      return;
    }
    setEnteredText(enteredText + event.key);
  };

  useEffect(() => {
    checkSelectedStore();
  }, []);

  return (
    <CSDProvider>
      <div className="container-fluid WelcomeBackground" id="main-container">
        <div className="row Navbar">
          <div className="col my-auto">
            <button type="button" className="btn my-auto" style={{ color: 'white' }} onClick={goToSetStore}>
              <span style={{ fontWeight: 'bold' }}>OLYNS</span> - {`${selectedStore?.company} ${selectedStore?.name}`.toUpperCase()}
            </button>
          </div>
          <div className="col my-auto text-end">
            <p className="my-auto" style={{ color: 'white' }}>{formatByTemplate(new Date(), 'hh:mmA, MMMM DD').toUpperCase()}</p>
          </div>
        </div>

        <div
          className="row"
          style={{
            height: '90vh', display: 'flex', alignItems: 'center', overflowY: 'auto',
          }}
        >
          <div className="col text-center">
            <img src={logo} alt="logo" width="20%" style={{ marginTop: appWidth ? 20 : 10 }} />
            <h4 style={{
              color: COLORS.DARK_GRAY, fontWeight: 'bold', marginTop: appWidth ? 30 : 20, paddingInline: appWidth ? '10%' : '0%',
            }}
            >Hold your phone or Olyns card over reader to log in.
            </h4>

            <div className="container-fluid text-start" style={{ paddingLeft: 20, paddingRight: 20, marginTop: appWidth ? 30 : 50 }}>
              <div className="row">
                <div className={appWidth ? 'row' : 'col'} style={{ display: 'flex', justifyContent: 'center', marginBlock: appWidth ? 15 : 0 }}>
                  <WelcomeWidget type={WidgetType.LOGIN} />
                </div>
                <div className={appWidth ? 'row' : 'col'} style={{ display: 'flex', justifyContent: 'center', marginBlock: appWidth ? 15 : 0 }}>
                  <WelcomeWidget type={WidgetType.BALANCE} />
                </div>
                <div className={appWidth ? 'row' : 'col'} style={{ display: 'flex', justifyContent: 'center', marginBlock: appWidth ? 15 : 0 }}>
                  <WelcomeWidget type={WidgetType.CASH} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading isVisible={isLoading} />
      <KeyboardEventHandler
        handleKeys={keys}
        onKeyEvent={(key: string, event: any) => { keyDownHandler(key, event); }}
      />
      <ToastBar isVisible={errorVisible} message={errorMessage} />
    </CSDProvider>
  );
};
