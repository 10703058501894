import { ClientError } from './ClientError';
import { getAuthToken } from './APIConfig';

// const BASE_URL = 'https://api-stage.olyns.com/api';
const BASE_URL = 'https://api.olyns.com/api';

type ParserFunction = (json: any) => object;
const noParser: ParserFunction = (json) => json;
const toJSON = (response: any) => response.json();
const parse = (parser: any) => (json: any) => parser(json);

const getBodyFromResponse = async (response: any) => {
  try {
    return await response.json();
  } catch (e) {
    return 'No body';
  }
};

const handleErrors = async (response: any) => {
  if (!response.ok) {
    const body = await getBodyFromResponse(response);
    if (response.status >= 500) {
      throw new Error(`Server error: ${JSON.stringify(response)}${body}`);
    }
    if (response.status >= 400) {
      throw new ClientError(body.message, body.error_code);
    }
    throw new Error(`Unexpected error: ${JSON.stringify(response)}${body}`);
  }
  return response;
};

const jsonFetch = async <T>(url: string, method: string, body?: object, parser: ParserFunction = noParser, authEndpoint = false): Promise<T> => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  const authToken = getAuthToken();

  if (authToken && authEndpoint) {
    headers.Authorization = `Token ${authToken}`;
  }

  const request: RequestInit = {
    method,
    headers,
  };

  if (body) {
    request.body = JSON.stringify(body);
  }

  const destination = `${BASE_URL}${url}`;

  return await fetch(destination, request)
    .then(handleErrors)
    .then(toJSON)
    .then(parse(parser));
};

const formDataFetch = async <T>(url: string, method: string, body?: any, parser: ParserFunction = noParser, authEndpoint = true): Promise<T> => {
  const headers: HeadersInit = {};

  const authToken = getAuthToken();

  if (authToken && authEndpoint) {
    headers.Authorization = `Token ${authToken}`;
  }

  const request: RequestInit = {
    method,
    headers,
  };

  if (body) {
    request.body = body;
  }

  const destination = `${BASE_URL}${url}`;
  return await fetch(destination, request)
    .then(handleErrors)
    .then(toJSON)
    .then(parse(parser));
};

export const get = async <T>(url: string, parser?: ParserFunction, body?: object, authorizedEndpoint = true) => jsonFetch<T>(url, 'GET', body, parser, authorizedEndpoint);

export const post = async <T>(url: string, body?: object, parser?: ParserFunction, authorizedEndpoint?: boolean) => jsonFetch<T>(url, 'POST', body, parser, authorizedEndpoint);

export const put = async <T>(url: string, body?: object, parser?: ParserFunction, authorizedEndpoint?: boolean) => jsonFetch<T>(url, 'PUT', body, parser, authorizedEndpoint);

export const del = async <T>(url: string, body?: object, parser?: ParserFunction, authorizedEndpoint?: boolean) => jsonFetch<T>(url, 'DELETE', body, parser, authorizedEndpoint);

export const postFile = async <T>(url: string, body?: object, parser?: ParserFunction, authorizedEndpoint?: boolean) => formDataFetch<T>(url, 'POST', body, parser, authorizedEndpoint);
