export class ClientError extends Error {
  readonly errorCode: string;

  constructor(message: string, errorCode: string) {
    if (message !== undefined) {
      super(message.replace('[', '')
        .replace(']', '')
        .replace('\'', '')
        .replace('.\'', '.'));
    } else {
      super(message);
    }
    this.errorCode = errorCode;
  }
}
